.todo-item {
    margin-bottom: 20px;
    display: flex;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
}

.save-todo-item {
    width: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px !important;
}

.MuiButtonBase-root-168 {
    padding: 12px 0 !important;
}

.crud-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add-item {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

a.navigation-link,
button.navigation-link {
    color: #fff;
    text-decoration: none;
}

.notification-success > div {
    background: #43a047 !important;
}

.notification-error > div {
    background: #d32f2f !important;
}

.notification-info > div {
    background: #1976d2 !important;
}

.notification-warning > div {
    background: #ffa000 !important;
}
